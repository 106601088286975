<template>
  <div id="legal-notice-screen">
    <div class="container">
      <div class="row">
        <div class="col-1"></div>
        <div class="col-10">
          <h1 class="headline-l">
            {{ $t("legalNoticeScreen.title") }}
          </h1>
          <p>
            {{ $t("legalNoticeScreen.paragraph_1") }}
          </p>
          <p>
            {{ $t("legalNoticeScreen.paragraph_2") }}
          </p>
          <p>
            {{ $t("legalNoticeScreen.paragraph_3") }}
          </p>
          <p>
            {{ $t("legalNoticeScreen.paragraph_4") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LegalNoticeScreen",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>
 
<style lang='scss' scoped>
.row {
  margin-top: 70px;
  margin-bottom: 70px;
}
p {
  color: $text-blue;
  margin-top: $margin-m;
}
</style>